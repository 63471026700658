<template>
  <div class="profile-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('page.InformationManagement')" name="first">
        <el-row>
          <el-col :span="12">
            <el-form ref="infoForm" :model="infoForm" label-width="80px" class="info-form">
              <el-form-item :label="$t('page.username')">
                <el-input v-model="infoForm.username" disabled />
              </el-form-item>
              <el-form-item :class="$i18n.locale" :label="$t('page.phoneNumber')">
                <el-input v-model="infoForm.mobile" />
              </el-form-item>
              <el-form-item :label="$t('page.Email')">
                <el-input v-model="infoForm.email" />
              </el-form-item>
              <el-form-item :label="$t('page.Avatar')">
                <el-upload
                  class="avatar-uploader"
                  :headers="uploadHeaders"
                  :action="action"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="infoForm.avatar" :src="infoForm.avatar" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleChangeInfo">{{ $t('page.submit') }}</el-button>
                <el-button>{{ $t('page.cancel') }}</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="$t('page.PasswordManagement')" name="second">
        <el-row>
          <el-col :span="12">
            <el-form ref="codeForm" :model="codeForm" label-width="80px" :rules="codeRules">
              <el-form-item :label="$t('page.Password')" prop="password">
                <el-input v-model="codeForm.password" show-password />
              </el-form-item>
              <el-form-item :class="$i18n.locale" :label="$t('page.Newpassword')" prop="newPassword">
                <el-input v-model="codeForm.newPassword" show-password />
              </el-form-item>
              <el-form-item :class="$i18n.locale" :label="$t('page.ConfirmPassword')" prop="confirmPassword">
                <el-input v-model="codeForm.confirmPassword" show-password />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleChangePw('codeForm')">{{ $t('page.submit') }}</el-button>
                <el-button @click="resetForm('codeForm')">{{ $t('page.reset') }}</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { usersEdit, getInfo } from '@/api/user'
import { cleanObj } from '@/utils'
// import UploadPhoto from '@/components/UploadPhoto'
export default {
  // components: {
  //   UploadPhoto
  // },
  data() {
    return {
      action: process.env.VUE_APP_UPLOAD_API,
      activeName: 'first',
      infoForm: {
        avatar: '',
        email: '',
        mobile: '',
        username: ''
      },
      codeForm: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      codeRules: {
        password: [
          { required: true, message: this.$t('page.originalPassword'), trigger: 'blur' },
          { min: 3, max: 12, message: this.$t('page.characters3to12'), trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: this.$t('page.newPassword'), trigger: 'blur' },
          { min: 3, max: 12, message: this.$t('page.characters3to12'), trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('page.newPasswordAgain'), trigger: 'blur' },
          { min: 3, max: 12, message: this.$t('page.characters3to12'), trigger: 'blur' },
          { validator: this.checkPassword, trigger: 'blur' }
        ]
      },
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      }
    }
  },
  computed: {
    passwordParams() {
      const { password, newPassword } = this.codeForm
      const { username } = this.infoForm
      return { password, newPassword, username }
    }
  },
  async  mounted() {
    const { datas } = await getInfo()
    this.infoForm = cleanObj(datas, ['avatar', 'email', 'mobile', 'username'])
    // console.log(this.infoForm)
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    handleAvatarSuccess(res, flie) {
      this.infoForm.avatar = res.datas
      // console.log({ res, flie })
    },
    beforeAvatarUpload() {},
    handleChangePw(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { msg } = await usersEdit(this.passwordParams)
          this.$message({
            type: 'success',
            message: msg
          })
          this.resetForm('codeForm')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async handleChangeInfo() {
      const { msg } = await usersEdit(this.infoForm)
      this.$message({
        type: 'success',
        message: msg
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    checkPassword(rule, value, callback) {
      if (this.codeForm.newPassword === value) {
        callback()
      } else {
        callback(new Error(this.$t('page.sameNewPassword')))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
